<template>
	<div class="aboutus">
		<site-header></site-header>
		<header-search></header-search>
		<bread-line :showLevel="1"></bread-line>

		<div class="joinus" v-html="content"></div>
		<!-- showLevel   //0 最后一级   1 第一级  2 两级 -->
		<!-- <nav-menu></nav-menu> -->
		<div style="width:100%;height:70px;background:rgba(240, 243, 248, 1)"></div>

		<index-footer></index-footer>
	</div>
</template>

<script>
import SiteHeader from '@/components/website/SiteHeader.vue'
import HeaderSearch from '@/components/website/HeaderSearch.vue'

import IndexFooter from '@/components/website/IndexFooter.vue'
import BreadLine from '@/components/website/BreadLine.vue'
import NavMenu from '@/components/website/NavMenu.vue'
import {detail} from '@/api/website/about'
export default {
	name: 'PcWebsiteIndex',
	components: { SiteHeader, HeaderSearch, NavMenu, IndexFooter, BreadLine },

	data() {
		return {
			content: ''
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	data() {
		return {
			type: 5,
			content: ''
		};
	},



	mounted() {
		this.getinfo()
	},

	watch: {
		
	},

	methods: {
		getinfo() {
			detail({ type: this.type }).then(res => {
				this.content = res.data.content
			})
		},
		tourl(path) {
			this.$router.push(path)
		}

	},
};
</script>

<style lang="scss" scoped>
.aboutus {
	width: 100%;
	height: auto;
	background: #F0F3F8;
}

.joinus {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	min-height: 400px;
	background: #fff;
	padding:10px;
	box-sizing: border-box;

}
</style>